// Global dependencies
import App from './app';

var $ = require('jquery');

console.log('JS initialized in version:', App.version);

$('.header__nav-toggler').on('click', function(){
	$(this).toggleClass('js-active');
	$( ".header__mainnav" ).slideToggle( "slow", function() {
		// Animation complete.
	});
});
